// SearchFlixList.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';
import withRouter from '../component/WithRouter.jsx';

import FlixListItem from '../container/FlixListItem.js';
import FlixCard from '../container/FlixCard.js';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';
import HydrationBoundary from '../component/HydrationBoundary.jsx';
import {
  InfiniteScroller,
  ScrollItem,
} from '../component/InfiniteScroller.jsx';
import Loading from '../component/Loading.jsx';
import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

import { breakpoint, color, textColor } from '../style/variables.js';
import ResetHeadingStyle from '../style/ResetHeadingStyle.js';

import { searchType } from '../resource/searchConstants.js';
import { CustomImpression } from '../resource/impressionConstants.js';

import MoreButtonIconSrouce from '../../img/ic_next_white.svg';
import media from '../style/media.js';

const ITEM_MAXIMUM_AMOUNT_MOBILE = 3; // TODO: remote config
const ITEM_MAXIMUM_AMOUNT_TABLET = 6; // TODO: remote config
const ITEM_MAXIMUM_AMOUNT = 8; // TODO: remote config

const isServer = typeof window === 'undefined';

export class SearchFlixList extends React.PureComponent {
  componentWillUnmount() {
    window.__IS_HYDRATED__ = 'SearchFlixList';
  }
  handleLoadMore = () => {
    const {
      sortingString,
      query,
      defaultCategory,
      nextPage,
      hasQuery,
      fetchMessageSearch,
      fetchFeeds,
    } = this.props;
    if (hasQuery) {
      return fetchMessageSearch({
        query,
        type: 'flix',
        queryUrl: nextPage,
        sortings: sortingString.split('&').filter(i => i),
      });
    }

    return fetchFeeds({
      page: nextPage,
      type: defaultCategory,
    });
  };

  renderCardItems = ({ id, index }) => {
    const { query, hasQuery, defaultCategory } = this.props;
    const shouldUseLazyImage =
      !isServer && !!window && !!window?.__IS_HYDRATED__;
    return (
      <WithIntersectionObserver key={id} threshold={0.2}>
        {({ isIntersecting }) => {
          return (
            <HydrationBoundary
              hydrationAnchor={`FlixCard-${hasQuery ? CustomImpression.FLIX_SEARCH : defaultCategory}-${index}`}
              shouldHydrate={isIntersecting}
              wrapper={<ItemWrapper />}
            >
              <FlixCard
                messageId={id}
                categoryId={
                  hasQuery ? CustomImpression.FLIX_SEARCH : defaultCategory
                }
                itemIndexInCategory={index}
                shouldShowAvatar
                shouldShowUsername
                shouldAnimateLoader={isIntersecting}
                isIntersecting={isIntersecting}
                matchedString={query}
                shouldShowLiveStream={false}
                shouldShowOnline={false}
                shouldUseLazyImage={shouldUseLazyImage}
              />
            </HydrationBoundary>
          );
        }}
      </WithIntersectionObserver>
    );
  };
  renderListItems = ({ id, index }) => {
    const { query, defaultCategory, hasQuery } = this.props;
    const shouldUseLazyImage =
      !isServer && !!window && !!window?.__IS_HYDRATED__;
    return (
      <WithIntersectionObserver key={id} threshold={0.2}>
        {({ isIntersecting }) => {
          return (
            <HydrationBoundary
              hydrationAnchor={`FlixListItem-${hasQuery ? CustomImpression.FLIX_SEARCH : defaultCategory}-${index}`}
              shouldHydrate={isIntersecting}
              wrapper={<ItemWrapper />}
            >
              <ListItemWrapper>
                <FlixListItem
                  shouldUseLink
                  shouldUseLazyImage={shouldUseLazyImage}
                  messageId={id}
                  matchedString={query}
                  id={id}
                  categoryId={
                    hasQuery ? CustomImpression.FLIX_SEARCH : defaultCategory
                  }
                  itemIndexInCategory={index}
                />
              </ListItemWrapper>
            </HydrationBoundary>
          );
        }}
      </WithIntersectionObserver>
    );
  };
  render() {
    const {
      nextPage,
      hasQuery,
      isOnSearchAll,
      location: { search },
      isNextPageFetching,
      resultIds,
      defaultIds,
      t,
      deviceWidth,
    } = this.props;
    const list = hasQuery ? resultIds : defaultIds;
    if (list.length === 0) {
      return null;
    }

    const isOnMobile = deviceWidth <= breakpoint.mobile;
    const isOnTablet = deviceWidth <= breakpoint.tablet;
    const shouldUseListLayout = isOnMobile;
    const maximumAmount = isOnMobile
      ? ITEM_MAXIMUM_AMOUNT_MOBILE
      : isOnTablet
        ? ITEM_MAXIMUM_AMOUNT_TABLET
        : ITEM_MAXIMUM_AMOUNT;

    const items = (isOnSearchAll ? list.slice(0, maximumAmount) : list).map(
      (id, index) =>
        shouldUseListLayout ? (
          this.renderListItems({ id, index })
        ) : (
          <CardWrapper key={id}>
            {this.renderCardItems({ id, index })}
          </CardWrapper>
        )
    );

    return (
      <StyledSearchFlixList isOnSearchAll={isOnSearchAll}>
        {hasQuery ? (
          isOnSearchAll ? (
            <TitleWrapper>
              <Title>
                {t('title_flix', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </Title>
              <MoreButton
                data-element_id={ButtonId.Search.ButtonMoreFlix}
                to={{ pathname: `/search/${searchType.FLIX}`, search }}
                replace
              >
                {t('btn_show_more', {
                  ns: TranslationNamespace.SEARCH,
                })}
                <img src={MoreButtonIconSrouce} alt="more" />
              </MoreButton>
            </TitleWrapper>
          ) : (
            <TitleWrapper paddingBottom={0} />
          )
        ) : (
          <TitleWrapper>
            <Title>
              {t('recommended', {
                ns: TranslationNamespace.SEARCH,
              })}
            </Title>
          </TitleWrapper>
        )}
        <main>
          {isOnSearchAll ? (
            <ItemsWrapper shouldUseListLayout={shouldUseListLayout}>
              {items}
            </ItemsWrapper>
          ) : (
            <InfiniteScroller
              pageStart={1}
              hasMore={nextPage !== null}
              loadMore={this.handleLoadMore}
              isFetching={isNextPageFetching}
              threshold={100}
            >
              <ItemsWrapper shouldUseListLayout={shouldUseListLayout}>
                <ScrollItem
                  loader={
                    <LoadingWrapper>
                      <Loading />
                    </LoadingWrapper>
                  }
                >
                  {items}
                </ScrollItem>
              </ItemsWrapper>
            </InfiniteScroller>
          )}
        </main>
      </StyledSearchFlixList>
    );
  }
}

SearchFlixList.propTypes = {
  sortingString: PropTypes.string,
  query: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  defaultCategory: PropTypes.string,
  nextPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isNextPageFetching: PropTypes.bool,
  hasQuery: PropTypes.bool,
  isOnSearchAll: PropTypes.bool,
  resultIds: PropTypes.array,
  defaultIds: PropTypes.array,
  fetchMessageSearch: PropTypes.func,
  fetchFeeds: PropTypes.func,
  t: PropTypes.func.isRequired,
  deviceWidth: PropTypes.number,
};

SearchFlixList.defaultProps = {
  sortingString: '',
  query: '',
  location: {},
  defaultCategory: null,
  nextPage: null,
  isNextPageFetching: false,
  hasQuery: false,
  isOnSearchAll: false,
  resultIds: [],
  defaultIds: [],
  fetchMessageSearch: () => null,
  fetchFeeds: () => null,
  deviceWidth: 0,
};

const StyledSearchFlixList = styled.div`
  background-color: ${color.black};
  padding: 0 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: ${({ paddingBottom = 20 }) => `${paddingBottom}px`};

  &:empty {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  ${media.mobile`
    padding-bottom: 14px;
    padding-bottom: ${({ paddingBottom = 14 }) => `${paddingBottom}px`};
  `}
`;

const MoreButton = styled(Link)`
  border-radius: 4px;
  height: 28px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textColor.white};
  font-size: 12px;
  line-height: 16px;
  border: solid 1px ${color.white};
  > img {
    width: 16px;
    height: 16px;
    margin-left: 4px;
  }
`;

const Title = styled.h1`
  ${ResetHeadingStyle};
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: ${color.white};
`;

const CardWrapper = styled.div``;

const ItemsWrapper = styled.div`
  ${({ shouldUseListLayout }) =>
    shouldUseListLayout
      ? ''
      : css`
          display: grid;
          gap: 8px;
          grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
        `}
`;

const ItemWrapper = styled.div`
  height: 100%;
`;

const LoadingWrapper = styled.div`
  height: 50px;
`;

const ListItemWrapper = styled.div`
  padding: 8px 0;
  min-height: 92px;
  border-bottom: 1px solid rgba(100, 100, 100, 0.5);
`;

export default withRouter(withTranslation()(SearchFlixList));
