// SearchFlixList.js
import { connect } from 'react-redux';

import fetchMessageSearch from '../action/fetchMessageSearch.js';
import fetchFeeds from '../action/fetchFeeds.js';
import getOperationData from '../selector/getOperationData.js';
import getListData from '../selector/getListData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import { getSelectedQueryOptionObject } from '../selector/getFeedQueryObject.js';
import getSelectedFeedQueryOptions from '../selector/getSelectedFeedQueryOptions.js';
import SearchFlixList from '../component/SearchFlixList.jsx';
import withSearchQuery from '../component/WithSearchQuery.jsx';

const mapStateToProps = (state, { query }) => {
  const { sorts: sortings } = getSelectedQueryOptionObject(
    state,
    '',
    getSelectedFeedQueryOptions(state, 'SEARCH_MESSAGE', undefined)
  );
  const sortingString = sortings.join('&');

  const hasQuery = !!query;
  const defaultCategory = getOperationData(
    state,
    ['recommendFlixCategory'],
    'search'
  );
  const searchSelectPath = ['search', 'message', 'flix', query];
  const defaultSelectPath = ['home', 'messages', defaultCategory];
  const selectPath = hasQuery ? searchSelectPath : defaultSelectPath;

  const resultIds = getListData(state, searchSelectPath, 'itemIds');
  const defaultIds = getListData(state, defaultSelectPath, 'itemIds');

  const nextPage = getListData(state, selectPath, 'nextPage');
  const isNextPageFetching = getNetworkingData(
    state,
    [...selectPath, nextPage],
    'isFetching'
  );
  const deviceWidth = getOperationData(state, ['device'], 'width');

  return {
    sortingString,
    query,
    defaultCategory,
    nextPage,
    hasQuery,
    isNextPageFetching,
    resultIds,
    defaultIds,
    deviceWidth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeeds: data => dispatch(fetchFeeds(data)),
    fetchMessageSearch: conditions => dispatch(fetchMessageSearch(conditions)),
  };
};

export default withSearchQuery(
  connect(mapStateToProps, mapDispatchToProps)(SearchFlixList)
);
