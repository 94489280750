// fetchMessageSearch.js
'use strict';
import parseLinkHeader from 'parse-link-header';
import debounce from 'lodash/debounce';

import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import pushToastr from '../action/pushToastr.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import objectifyArrayById from '../resource/objectifyArrayById.js';
import getMeData from '../selector/getMeData.js';
import getListData from '../selector/getListData.js';
import {
  ADD_MESSAGES,
  ADD_LIST_ITEMS,
  SET_LIST_ITEMS,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

const debounceFunctionMap = {};

export const fetchMessageSearch = payload => async dispatch => {
  const { type } = payload;
  if (!debounceFunctionMap[type]) {
    debounceFunctionMap[type] = debounce(
      payload => dispatch(fetchMessageSearchCore(payload)),
      500 // TODO: remote config
    );
  }
  return debounceFunctionMap[type](payload);
};

/**
 * Fetch message search
 * @kind action
 * @param {string} { v } - API version.
 * @param {string} { query } - search query.
 * @param {string} { queryUrl } - completed search url.
 * @param {number} { limit } - page size.
 * @param {string} { index } - support user/message/hashtag searching.
 * @param {string} { type } - story | flix
 * @param {array} { [sortings = []] } - sortings.
 * @param {object} { [httpProxyHeaders = {}] } - http proxy headers for SSR.
 * @return {Promise} Action promise.
 */
export const fetchMessageSearchCore =
  ({
    v = '2',
    query,
    queryUrl,
    limit = 10,
    index = 'message',
    type = 'story',
    sortings = [],
    httpProxyHeaders = {},
  }) =>
  async (dispatch, getState) => {
    const state = getState();
    const token = getMeData(state, 'token');

    const headers = new Headers({
      ...getHeaders(),
      ...httpProxyHeaders,
    });
    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    const listSelectPath = ['search', 'message', type, query];
    const selectPath = [...listSelectPath, queryUrl || 'firstPage'];

    let url;

    if (queryUrl) {
      try {
        url = new URL(queryUrl);
      } catch (error) {
        dispatch(
          pushToastr({ textKey: 'something_went_wrong', color: 'error' })
        );
      }
    } else {
      url = getResourceUrl({ endpoint: '/search' });
      url.searchParams.append('v', v);
      url.searchParams.append('q', query);
      url.searchParams.append('index', index);
      url.searchParams.append('limit', limit);
      if (type === 'flix') url.searchParams.append('type', type);
      if (sortings.length)
        sortings.forEach(sorting =>
          url.searchParams.append('sorting', sorting)
        );
    }

    const fetchOptions = {
      headers,
    };

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });

    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const links = parseLinkHeader(response.headers.get('Link'));
      const nextPage = links?.next?.url;
      const totalCountString = response.headers.get('x-total-count');
      const totalCount =
        totalCountString == null ? undefined : +totalCountString;

      const messagesArray = await response.json();
      const messages = objectifyArrayById({
        array: messagesArray.map(message => {
          return {
            ...message,
            senderId: message.sender,
            expiresAtUnix: message.expiresAt,
            postedAtUnix: message.postedAt,
          };
        }),
      });
      const itemIds = Object.keys(messages) || [];
      const isFirstPage = !queryUrl;

      // Use current items.legnth as total count since backend not return x-total-count
      const shouldUseLocalTotalCount = totalCount == null;
      const currentItemIds =
        getListData(getState(), listSelectPath, 'itemIds') ?? [];

      dispatch({ type: ADD_MESSAGES, payload: { messages } });
      dispatch({
        type: isFirstPage ? SET_LIST_ITEMS : ADD_LIST_ITEMS,
        payload: {
          selectPath: listSelectPath,
          itemIds,
          nextPage,
          totalCount: shouldUseLocalTotalCount
            ? (isFirstPage ? 0 : currentItemIds.length) + itemIds.length
            : totalCount,
        },
      });

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchMessageSearch;
