// WithSearchQuery.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import getOperationData from '../selector/getOperationData.js';

export const WithSearchQuery = Component => {
  const ComponentWithSearchQuery = props => {
    const { search } = useLocation();
    const query =
      useSelector(state => getOperationData(state, ['search'], 'query')) ||
      new URLSearchParams(search).get('q') ||
      '';
    return <Component query={query} {...props} />;
  };
  return ComponentWithSearchQuery;
};

export default WithSearchQuery;
